import { environment } from '@env/environment';
export interface ApiUrl {
  path: string;
  server: string;
}
export const APIS_URL = {
  getTokenSymfony: {
    path: '/api/login_check',
    server: environment.symfony
  },
  getGuestByEmail: {
    path: '/api/guest/email/{email}',
    server: environment.symfony
  },
  apiGipLogin: {
    path: '/api/gip/login',
    server: environment.symfonycommon
  },
  apiCheckUser: {
    path: '/api/drupal/check-user',
    server: environment.symfonycommon
  },
  getTokenCommonSymfony: {
    path: '/api/login_check',
    server: environment.symfonycommon
  },
  bookingCheck: {
    path: '/api/booking/check',
    server: environment.symfony
  },
  registerUser: {
    path: '/api/gip/user',
    server: environment.symfonycommon
  }
};

export const getUrlMatched = (apiurl: ApiUrl, params: Record<string, any> = {}): string => {
  let path = apiurl.path;
  Object.keys(params).forEach((key) => {
    path = path.replace(`{${key}}`, params[key]);
  });
  return apiurl.server + path;
};
