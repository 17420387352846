import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { SymfonyService } from './symfony.service';
import { Observable, switchMap } from 'rxjs';
import { APIS_URL } from '@app/core/consts/apis-url';
import { environment } from '@env/environment';
import { Injectable } from '@angular/core';

@Injectable()
export class SymfonyBearerInterceptor implements HttpInterceptor {
  constructor(private symfonyService: SymfonyService) {}
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (
      request.url.includes(APIS_URL.getTokenSymfony.path) ||
      request.url.includes(APIS_URL.getTokenCommonSymfony.path)
    ) {
      return next.handle(request);
    }
    if (request.url.includes(environment.symfony)) {
      return this.symfonyService.getTokenSymfony().pipe(
        switchMap((token) => {
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`
            }
          });
          return next.handle(request);
        })
      );
    }

    if (request.url.includes(environment.symfonycommon)) {
      return this.symfonyService.getTokenCommonSymfony().pipe(
        switchMap((token) => {
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`
            }
          });
          return next.handle(request);
        })
      );
    }

    return next.handle(request);
  }
}
