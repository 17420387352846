import { Injectable } from '@angular/core';
import { AuthData } from '@app/core/models';
import { StorageService } from '../stotage/storage.service';
import { Observable, firstValueFrom, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { initializeApp, FirebaseApp } from 'firebase/app';
import { onAuthStateChanged, signInWithEmailAndPassword, getAuth, Auth, User, UserCredential } from 'firebase/auth';
import { environment } from '@env/environment';
import { SymfonyService } from '../symfony/symfony.service';

@Injectable({
    providedIn: 'root'
})
/**
 * Simple class to manage the app auth
 */
export class AuthService {
    public app: FirebaseApp;
    public auth: Auth;
    private oldWebDead = false;
    constructor(
        private storageService: StorageService,
        private http: HttpClient,
        private symfonyService: SymfonyService
    ) {
        const firebaseConfig = {
            apiKey: environment.identityProviderApiKey,
            authDomain: environment.identityProviderAuthDomain
        };
        this.app = initializeApp(firebaseConfig);
        this.auth = getAuth(this.app);
        onAuthStateChanged(this.auth, (user) => {
            if (user) {
                console.log('LOGINAPP> auth user: ', { user });
            } else {
                return;
            }
        });
    }

    /**
     * Method to call the loging service
     * @param authData data to call login service
     */
    public login(authData: AuthData): Observable<boolean> {
        // Implementar la logica de autenticacion de la aplicacion aqui.
        return this.dummyLogin(authData);
    }

    /**
     * Dummy implementation to test login  and fail
     */
    dummyLogin(authData: AuthData) {
        if (authData.username === 'pepe') {
            return of(false);
        }

        this.storageService.setItem('ACCESS_TOKEN', 'access_token');

        return of(true);
    }

    /**
     * Method to check if the user is logged
     */
    public isLoggedIn() {
        return this.storageService.getItem('ACCESS_TOKEN') !== null;
    }

    /**
     * Method to logout the user
     */
    public logout() {
        this.storageService.removeItem('ACCESS_TOKEN');
    }

    /**
     * Method to auth with Google Identity Provider
     */
    public async authGIP(username: string, password: string): Promise<any> {
        /* eslint-disable sonarjs/cognitive-complexity */
        const userNotGIP = async (error: any) => {
            const message = error.message;
            if (message.includes('auth/user-not-found')) {
                // Call service for autologin
                if(this.oldWebDead) return Promise.reject('user-not-found-gip');
                try{
                    const check = await firstValueFrom(this.symfonyService.checkUserDrupal(username, password));
                    console.log('LOGINAPP> check user drupal response: ', check);
                    if(!check.responseTms.success) return Promise.reject('USER NOT FOUND IN DRUPAL');
                    const dataRes = [{
                        'Email': check.responseTms.data.email,
                        'GuestId': check.responseTms.data.huespedId
                    }]
                    return Promise.resolve(dataRes);
                }   
                catch(error: any) {
                    console.log({error});
                    return Promise.reject('check-user-drupal-failed');
                }

            }
            if(message.includes('auth/wrong-password')) return Promise.reject('wrong-password');
            return Promise.reject(message);
        }
        try {
            const userCredentials: UserCredential = await signInWithEmailAndPassword(this.auth, username, password);
            const user: User = userCredentials.user;
            try {
                // FUTURE TODO: Choose endpoint check between Catalonia Symfony or Rewards System
                const getEmailSymfony$ = this.symfonyService.getEmailSymfony(user.email ?? '##');
                return await firstValueFrom(getEmailSymfony$);
            } catch (error) {
                console.log('LOGINAPP> User in GIP but not in symfony: ', error);
                return Promise.reject('user-not-found-symfony');
            }
        } catch (error: any) { // This only pick GIP errors
            return userNotGIP(error);
        }
    }
}

