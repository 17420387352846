import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { JWTInterceptor } from './core/providers/jwt/jwt.interceptor';
import { CoreModule } from './core/core.module';
import { LayoutModule } from './modules/layout/layout.module';
import { SymfonyBearerInterceptor } from './core/providers/symfony/symfony-bearer.interceptor';
import { LoadingInterceptor } from './core/providers/loading-interceptor/loading.interceptor';

@NgModule({
  declarations: [AppComponent],
  imports: [CoreModule, LayoutModule, AppRoutingModule, HttpClientModule],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JWTInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SymfonyBearerInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
