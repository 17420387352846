export const environment = {
  production: false,
  mock_server: false,
  symfony: 'https://api-qa.cataloniahotels.com',
  symfonycommon: 'https://apicomun-qa.cataloniahotels.com',
  symfonyreader: 'dXNlckBlbWFpbC5jb206cGFzc3dvcmQxMjM=',
  symfonycommonreader: 'dXNlckBlbWFpbC5jb206cGFzc3dvcmQxMjM=',
  identityProviderApiKey: 'AIzaSyCvFMSkx3bX53pdyS_skAikDfizp2nxD38',
  identityProviderAuthDomain: 'prj-d-web-dev.firebaseapp.com',
  API_URL: 'http://localhost'
};
