<div class="container">
  <div class="row">
    <nav>
      <a href="/projects">Projectos</a> |
      <a href="/users">Ususarios</a>
    </nav>
  </div>
  <div class="row">
    <div class="col-2"><span>COLUMNA</span></div>
    <div class="col-10">
      <span>CONTENIDO</span>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
