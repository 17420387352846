<header></header>
<main>
  <div class="form-content">
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
      <div *ngIf="errorMsgKey" class="field-error">{{ errorMsgKey | translate }}</div>
      <div class="form-group">
        <label *ngIf="loginForm.controls['username'].value !== ''">Username:</label>
        <input type="text" id="username" name="username" formControlName="username" placeholder="Username" required />
        <div *ngIf="fieldError('username')" class="field-error">
          <span *ngIf="loginForm.controls['username'].errors?.['required']">
            {{ 'LOGIN.VALIDATION.USER-NAME-REQUIRED' | translate }}
          </span>
        </div>
      </div>
      <div class="form-group">
        <label *ngIf="loginForm.controls['password'].value !== ''">Password:</label>
        <input type="text" id="password" name="password" formControlName="password" placeholder="Password" required />
        <div *ngIf="fieldError('password')" class="field-error">
          <span *ngIf="loginForm.controls['password'].errors?.['required']">
            {{ 'LOGIN.VALIDATION.PASSWORD-REQUIRED' | translate }}
          </span>
        </div>
      </div>
      <input type="submit" value="Log In" />
    </form>
  </div>
</main>
<footer></footer>
