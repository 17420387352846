import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppLayoutComponent } from './app-layout/app-layout.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { MainComponent } from './components/main/main.component';
import { Error404PageComponent } from './error404-page/error404-page.component';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [CommonModule, RouterModule],
  declarations: [AppLayoutComponent, HeaderComponent, FooterComponent, Error404PageComponent, MainComponent],
  exports: [Error404PageComponent, AppLayoutComponent]
})
export class LayoutModule {}
