import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { AuthService } from '@app/core/providers/auth/auth.service';
import { AuthData } from '@app/core/models';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent {
  public errorMsgKey: string | null = null;

  loginForm: FormGroup = this.fb.group({
    username: ['', Validators.required],
    password: ['', Validators.required]
  });
  isSubmitted = false;

  constructor(private fb: FormBuilder, private authService: AuthService, private router: Router) {}

  /**
   *
   * @param field field to check if is valid
   */
  fieldError(field: string) {
    return (
      this.loginForm.controls[field].invalid &&
      (this.loginForm.controls[field].dirty || this.loginForm.controls[field].touched)
    );
  }

  /**
   * Method to do the login
   */
  onSubmit() {
    if (this.isSubmitted) {
      console.log('ya esta no lo mandados');
      return;
    }

    this.hideError();

    if (this.loginForm.invalid) {
      // notify to user that needs to complete the form
      this.showError('LOGIN.VALIDATION.EMPTY-FIELD');

      return;
    }

    const data: AuthData = {
      username: this.loginForm.controls['username'].value,
      password: this.loginForm.controls['password'].value
    };

    this.isSubmitted = true;
    this.authService.login(data).subscribe((isLogded: boolean) => {
      this.isSubmitted = false;

      if (isLogded) {
        this.router.navigate(['projects']);
      } else {
        // mostrar error de login
        this.showError('LOGIN.ERRORS.INVALID-CREDENTIAL');
      }
    });
  }

  /**
   * Method to show the error
   * @param key of I18n  to translate the error
   */
  showError(key: string) {
    this.errorMsgKey = key;
  }

  /**
   * Method to hide login error
   */
  hideError() {
    this.errorMsgKey = null;
  }

  /**
   * Method to check if the form is correcti
   */
  checkData(): boolean {
    return this.loginForm.controls['username'].value !== '' && this.loginForm.controls['password'].value !== '';
  }
}
