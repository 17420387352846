import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { TranslateModule } from '@ngx-translate/core';

const primengModules = [ButtonModule];

const appModules = [CommonModule, ReactiveFormsModule];

const appComponents: any[] = [];
@NgModule({
  declarations: [...appComponents],
  imports: [TranslateModule.forChild(), ...appModules, ...primengModules],
  exports: [TranslateModule, ...appModules, ...primengModules, ...appComponents]
})
export class SharedModule {}
