import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { LoginPageComponent } from './modules/login/login-page/login-page.component';
// import { AuthGuard } from './core/providers/auth-guard/auth.guard';
// import { AppLayoutComponent } from './modules/layout/app-layout/app-layout.component';
import { Error404PageComponent } from './modules/layout/error404-page/error404-page.component';

const routes: Routes = [
  // {
  //   path: '',
  //   component: AppLayoutComponent,

  //   children: [
  //     {
  //       path: 'users',
  //       loadChildren: () => import('./modules/users/users.module').then((m) => m.UsersModule)
  //     },
  //     {
  //       path: 'projects',
  //       loadChildren: () => import('./modules/projects/projects.module').then((m) => m.ProjectsModule)
  //     }
  //   ],
  //   canActivate: [AuthGuard],
  //   runGuardsAndResolvers: 'always'
  // },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'forms'
  },
  { path: 'login', component: LoginPageComponent },
  {
    path: 'forms',
    loadChildren: () => import('./modules/forms-frame/forms-frame.module').then((m) => m.FormsFrameModule)
  },
  { path: '**', component: Error404PageComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
