import { environment } from '@env/environment';
import { rest } from 'msw';

import * as users from '../data/users/users.json';

export default [
  rest.get(`${environment.API_URL}/users`, (req, res, ctx) => {
    return res(ctx.json(users));
  })
];
