import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APIS_URL, getUrlMatched } from '@app/core/consts/apis-url';
import { Observable, of, switchMap } from 'rxjs';
import { StorageService } from '../stotage/storage.service';
import { environment } from '@env/environment';
import { Buffer } from 'buffer';

@Injectable({
  providedIn: 'root'
})
export class SymfonyService {
  constructor(private http: HttpClient, private storageService: StorageService) {}

  public getTokenSymfony(): Observable<string> {
    // Check if it is not cached
    const token = this.storageService.getItem('bearer-token');
    if (token) return of(token);
    const endpoint = getUrlMatched(APIS_URL['getTokenSymfony']);
    const creds = Buffer.from(environment.symfonyreader, 'base64').toString('binary');
    const [username, password] = creds.split(':');
    const credentials = { username, password };
    return this.http.post<any>(endpoint, credentials).pipe(
      switchMap((res) => {
        this.storageService.setItem('bearer-token', res.token);
        return of(res.token);
      })
    );
  }

  public getTokenCommonSymfony(): Observable<string> {
    const token = this.storageService.getItem('commonsymfony-bearer-token');
    if (token) return of(token);
    const endpoint = getUrlMatched(APIS_URL['getTokenCommonSymfony']);
    const creds = Buffer.from(environment.symfonycommonreader, 'base64').toString('binary');
    const [username, password] = creds.split(':');
    const credentials = { username, password };
    return this.http.post<any>(endpoint, credentials).pipe(
      switchMap((res) => {
        this.storageService.setItem('commonsymfony-bearer-token', res.token);
        return of(res.token);
      })
    );
  }

  public getEmailSymfony(email: string): Observable<any> {
    const endpoint = getUrlMatched(APIS_URL['getGuestByEmail'], { email });
    return this.http.get<any>(endpoint);
  }

  public loginGip(email: string, password: string): Observable<any> {
    const endpoint = getUrlMatched(APIS_URL['apiGipLogin'], {});
    const bodyData = { email, password };
    return this.http.post<any>(endpoint, bodyData);
  }

  public checkUserDrupal(email: string, password: string): Observable<any> {
    const endpoint = getUrlMatched(APIS_URL['apiCheckUser'], {});
    const bodyData = { email, password };
    return this.http.post<any>(endpoint, bodyData);
  }

  public bookingSearchCheck(data: any) {
    const endpoint = getUrlMatched(APIS_URL['bookingCheck'], {});
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    return this.http.post<any>(endpoint, formData);
  }

  public registerUserGIP(data: any) {
    const endpoint = getUrlMatched(APIS_URL['registerUser'], {});
    return this.http.post<any>(endpoint, data);
  }
}
